<div class="container-fluid">
    <div class="row">
        <div id="login-section" class="col-md-5">
            <div class="row">

                <div class="col-md-offset-2 col-md-8">
                    <!-- <div class="text-center">
              <a class="logo btn">
                LOGO
              </a><br> -->
                    <div class="text-center">
                        <a class="logo btn">

                            <!-- logo for cgsl (local site) -->
                            <!-- <img src="../../../assets/img/cgsl_logo.png" alt=""> -->

                            <!-- logo for pointclickstay (foreign site) -->
                            <img src="../../../assets/img/pci.png" alt="" style="width:70%">

                        </a><br>
                        <small class="sub-title">LOGIN TO YOUR</small>
                        <h3 class="title no-margin">Property Management System</h3><br>
                        <div class="alert alert-success" *ngIf="successMessage != null">
                            <a class="close" data-dismiss="alert" aria-label="close">&times;</a> {{successMessage}}
                        </div>
                        <div class="alert alert-danger" *ngIf="errorMessage != null">
                            <a class="close" data-dismiss="alert" aria-label="close">&times;</a> {{errorMessage}}
                        </div>
                    </div>


                    <form method="POST" novalidate #form="ngForm" class="form-horizontal" (ngSubmit)="login(form)">
                        <br>
                        <!-- <label class="control-label cg-label">Email</label> -->
                        <input type="text" name="email" class="form-control cg-input" placeholder="Email"
                            [(ngModel)]="email" required pattern="^[A-Za-z0-9@.]+$" />
                        <br>

                        <!-- <label class="control-label cg-label">Password <span class="fg-pass-span"><a class="" routerLink="forgot_password">Forgot password</a></span></label> -->
                        <!-- <input type="password" name="pass" class="form-control cg-input" [(ngModel)]="pass" required minlength="6"> -->
                        <div class="nameIcon">

                            <input name="pass" class="form-control cg-input" placeholder="Password" [(ngModel)]="pass"
                                required [type]="showPassword ? 'text' : 'password'" />
                            <i class="pass_eye" [ngClass]="showPassword ? 'fa fa-eye-slash' : ' fa fa-eye'"
                                (click)="togglePasswordVisibility()" aria-hidden="true"></i>
                            <!-- <mat-icon class="pass_eye"  (click)="togglePasswordVisibility()"> {{showPassword?'visibility_off':'visibility'}}</mat-icon> -->
                        </div>
                        <br>

                        <label>
                            <input type="checkbox" name="remember" class="cg-checkbox no-margin"
                                [(ngModel)]="remember" />
                            <span class="remember-email">Remember Email</span>
                        </label>
                        <br>
                        <button class="btn btn-info btn-block cg-btn login-btn" type="submit">LOGIN</button>
                        <br>
                        <p class="create-p">Don't Have an Account ? &nbsp;
                            <!-- for the local site -->
                             <!-- <a class="create-a" href="https://www.cgslhcm.com/signup">CREATE BUSINESS ACCOUNT</a> -->

                             <!-- for the Foriegn site -->
                             <a class="create-a" href="https://www.pointclickstay.com/create_account">CREATE BUSINESS ACCOUNT</a>
                        </p>
                        <!-- <a class="btn btn-block cg-btn-blank" routerLink="reset-password/MeCgvUpOlrqymen2OcjqLWoV0DpgeCRbKmLeLn4UUfksTWj6x1LP4fnBKrwR">Reset password</a> -->
                        <!-- <a class="btn btn-block cg-btn-blank" (click)="confirmBox()" data-toggle="modal" data-target="#confirmationModal">Test</a> -->
                    </form>
                    <br><br><br><br>

                    <div class="footer">
                        <div class="row">
                            <div class="col-md-6">
                                <small>Copyright {{ current_date }} CGSL</small>
                            </div>

                            <!-- for the local site  -->

                            <!-- <div class="col-md-6 text-right">
                                <small><a href="https://www.cgslhcm.com/contactus">Contact Support</a></small>
                            </div> -->

                                            <!-- for the forign site  -->
                             <div class="col-md-6 text-right">
                                <small><a href="https://www.pointclickstay.com/contactus">Contact Support</a></small>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-md-7 login-bg"></div>
    </div>
</div>
<app-confirmation-alert></app-confirmation-alert>